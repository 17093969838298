@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");

.connectionErrorModal {
  animation: fadeIn 1s ease both;
  background: none;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;

  .content {
    width: 400px;
    padding: 1rem 3rem;
    padding-top: 2rem;
    border-radius: 10px;
    font-family: Poppins, sans-serif;

    h2 {
      font-weight: 600;
      font-size: 2rem;
    }

    p {
      font-size: 1rem;
      line-height: 1rem;
      padding: 0.5rem 0;
    }

    button {
      margin: 5px;
    }
  }

  .reload-btn {
    background: green;
    color: black;
    border: 1px solid black;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      background: black;
      color: white;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      backdrop-filter: blur(0px);
    }
    to {
      opacity: 1;
      backdrop-filter: blur(8px);
    }
  }
}
