.changelog {
  text-align: center;

  h1 {
    margin-top: 20px;
    font-size: 32px;
  }

  h2 {
    font-size: 28px;
  }

  h1, h2 {
    margin: 0 0 20px;
    font-family: 'courier new', courier;
    color: #fff;
  }

  ul, li {
    color: #fff;
    margin: 0;
    padding: 0;
    line-height: normal;
    font-size: 15px;
    align-content: left;
  }

  li {
    font-weight: bold;
    list-style: none;
    font-size: 20px;
  }

  .important {
    color: rgb(255, 0, 0);
  }

  .golden {
    background: linear-gradient(
    to right, 
    rgb(255, 255, 0), 
    rgb(194, 167, 14), 
    rgb(218, 218, 12), 
    rgb(212, 183, 16), 
    yellow, 
    rgb(224, 224, 11)
  );
    border: 5px solid red;
  }

  .announcement {
    color: red
  }

  .primary-link {
    color: rgb(119, 162, 255);
    text-decoration: underline;
    font-size: 120%;
    font-weight: bold;
    margin: 20px auto;
    font-size: 18px;
  }
  
}
