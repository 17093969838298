.loading-screen {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  background-color: #333333;
  width: 100%;
  height: 100%;
	transition: 500ms all ease-in-out;

  .loading-container {
    width: 400px;
    text-align: center;

    .loading-text {
      color: #ffffff;
      font-size: 45px;
      margin-bottom: 10px
    }

    .progress-bar {
      width: 100%;
      height: 20px;
      background-color: #eee;

      .progress {
        height: 100%;
        background-color: lime;
        transition: width 0.5s ease-in-out;
      }
    }

    p {
      color: white;
    }
  }
}
