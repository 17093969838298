/* General Styles */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Modal Styles */
.shop-modal {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 140px);
  width: calc(100vw - 100px);
  max-width: 1000px;
  margin: 20px auto;
  background: linear-gradient(to top left, #011702 5%, rgb(5, 63, 7) 100%);
  box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
  padding: 20px;
  border-radius: 10px;
  color: #ffffff;
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  overflow: hidden;
  /* White text color for modal */

}

.label {
  margin-left: 10px;
  font-size: 30px;
}

.label hr {
  margin-top: 0;
  border-color: #000;
}

.label p {
  margin-top: -7px; 
  font-size: 13px;
  color: lightgray;
}
.shop-extra {
  width: 20%;
  max-width: 300px;
  min-width: 100px;
  font-size: 10%;
  margin-right: 10px;
}
/* Skins Container Styles */
.scroll {
  overflow-y: auto;
  overflow-x: hidden;
  width: 82%;
  background-color: #00000068;
  margin-top: -20px;
  padding-top: 20px;
  margin-bottom: -20px;
  padding-bottom: 20px;
}
.skins {
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: flex-start;
}

.shop-title {
  font-size: 50px;
  text-align: center;
  color: #ffffff;
  /* Adjusting header color to fit modal style */
  margin: 0;
}

.shop-desc {
  font-size: 15px;
  text-align: center;
  color: #ffffff;
  /* Adjusting header color to fit modal style */
  margin: 0;
  margin-bottom: 10px;
}

.gem {
  transform: translate(0, 25%);
}

/* Skin Card Styles */
.skin-card {
  margin: 10px;
  border-radius: 10px;
  background-color: #ffffff4f;
  /* Dark background for cards */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  width: 130px;

  .skin-name {
    font-size: 17.5px;
    color: white;
    text-align: center;
    font-weight: 700;
  }

  .skin-tag {
    color: magenta;
    font-size: 14px;
    margin-top: -10px;
    text-align: center;
  }

  .skin-saletag {
    color: yellow;
    font-size: 20px;
    margin-top: -10px;
    text-align: center;
  }

  .sale {
    text-decoration: line-through;
  }

  .skin-eventtag {
    color: cyan;
    font-size: 16px;
    margin-top: -10px;
    text-align: center;
  }

  .skin-img {
    width: 110px;
    height: 110px;
    background-size: cover;
    background-position: center;
  }

  .skin-sword {
    width: 104px;
    height: 112px;
    background-size: cover;
    background-position: center;
  }

  .skin-count {
    font-size: 14px;
    color: white;
    text-align: center;
    font-weight: normal;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .buy-button {
    color: #ffffff;
    padding: 0.8rem;
    margin-top: 2.5px;
    font-size: 10px;
    text-transform: uppercase;
    border-radius: 2px;
    font-weight: 400;
    display: block;
    width: 100%;
    cursor: pointer;
    border: 0.5px solid rgba(255, 255, 255, 0.2);
    background: transparent;

    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
}

// low height
@media screen and (max-height: 600px) {

  .skin-card {
    margin: 5px;


    .skin-img {
      width: 50px;
      height: 50px;
      margin: 0;
    }

    .skin-sword {
      width: 52px;
      height: 56px;
      margin: 0;
    }

    .skin-name {
      font-size: 20px;
      margin: 0;
    }

    .skin-count {
      font-size: 15px;
      margin: 0;
    }

    .buy-button {
      font-size: 12px;
      margin: 0;
      margin-top: 5px;
      padding: 0.2rem;
    }

    .skin-desc {
      font-size: 12px;
      margin: 0;
    }


  }

  .shop-title {
    font-size: 20px;
  }

  .shop-desc {
    font-size: 15px;
  }
}

/* General Styling Adjustments */
h1 {
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
  /* Adjusting header color to fit modal style */
}

.highlight {
  background-color: yellow;
}

.search-bar {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  margin-left: -50%;
  margin-right: -50%;
}

.search-bar input {
  width: 50%;
  padding: 5px;
  border: none;
  border-radius: 20px;
  box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.06);
  font-size: 15px;
}

.search-bar input:focus {
  outline: none;
  box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.1);
}

.search-bar input::placeholder {
  color: #aaa;
}

.badges {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.badges button {
  margin: 5px;
  padding: 10px 10px;
  border: none;
  border-radius: 10px;
  background-color: #ddd;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.badges button.active {
  background-color: gray;
  border: 3px solid #000;
}

.badges button[data-selected-badge="og"]:not(.active) {
  background-color: #ff0000;
}

.badges button[data-selected-badge="event"]:not(.active) {
  background-color: #00ffff;
}

.badges button[data-selected-badge="player"]:not(.active) {
  background-color: #0000ff;
}

.badges button[data-selected-badge="own"]:not(.active) {
  background-color: #00ff00;
}


.badges button[data-selected-badge="sale"]:not(.active) {
  background-color: #ffff00;
}

.badges button[data-selected-badge="ultimate"]:not(.active) {
  background-color: #ff00ff;
}