body {
  margin: 0;
  padding: 0;
  font-family: 'Franklin Gothic Medium', 'Ubuntu', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  cursor: url(assets/img/cursor.cur) 20 20, crosshair;
}

html, body {
  width: 100%;
	height: 100%;
  position: relative;
}

.grecaptcha-badge {
  visibility: hidden;
}

// gamemonetize div
#imaContainer,
#imaContainer_new {
 cursor: default;
}
