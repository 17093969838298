.settings {
  .settings-title {
    font-size: 30px;
    margin: 0 0 20px;
    letter-spacing: 2px;
  }

  .section {
    text-decoration: underline;
    font-size: 22px;
  }

  label {
    display: block;
    margin-bottom: 10px;
  }

  .settings-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  select, input {
    width: 100%;
    margin: 0 0 10px;
    font-size: 18px;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 21px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;

    &:before {
      position: absolute;
      content: "";
      height: 15px;
      width: 15px;
      left: 6px;
      bottom: 3px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }
  }

  input:checked + .slider {
    background-color: #2196F3;

    &:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
}

.slider.round {
  border-radius: 34px;

  &:before {
    border-radius: 50%;
  }
}
