.leaderboard {
  position: fixed;
  top: 10px;
  right: 10px;
  width: fit-content;
  padding: 5px;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
	text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
  transform-origin: top right;
  user-select: none;
  pointer-events: none;

  .leaderboard-title {
    margin-bottom: 5px;
    text-align: center;
    font-size: 24px;
    pointer-events: all;

    &:hover {
      cursor: pointer;
    }
  }

  .leaderboard-content {
    opacity: 1;
    transition: all 0.2s ease-in;

    &.hidden {
      opacity: 0;
    }
  }
}

.leaderboard-line {
  display: block;
  font-size: 22px;
  white-space: nowrap;
}
