.panel {
  padding: 15px;
  margin: 8px;
  border-radius: 10px;
  background-color: rgb(34, 34, 34) !important;
  box-shadow:  0 0 0 3px rgba(51, 51, 51, 0.98);
  color: rgb(231, 231, 231);
  box-sizing: border-box;
}

.shop-btn{
  position: absolute;


  // left
  left: 0%;
  top: 0%;
  transform-origin: left;
  cursor: pointer;


  img {
    height: auto;
    display: inline-block;
    margin-left: 10px;
    margin-top: 10px;
    transition: transform 0.3s ease;
  }

  img:hover {
    transform: scale(1.1);
  }
}

.leaderboard-btn{
  position: fixed;


  // left
  left: 0%;
  top: 85px;
  transform-origin: left;
  cursor: pointer;


  img {
    height: auto;
    display: inline-block;
    margin-left: 10px;
    margin-top: 10px;
    transition: transform 0.3s ease;
  }

  img:hover {
    transform: scale(1.1);
  }
}

footer {
  position: absolute;
  bottom: 10px;
  width: 100%;
  pointer-events: none;
  height: 50px;
  color: rgb(231, 231, 231);
  text-align: center;
  padding-top: 15px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 30px;

  a {
    font-size: 30px;
    text-decoration: underline;
    pointer-events: auto;
  }

}

.auth-buttons {
  position: absolute;
  top:5px;
  right: 5px;
  display: flex;
  flex-direction: column;
  transform-origin: top right;

  .dropdown {

    .dropdown-menu {
      background-color: #f9f9f9;
      padding: 12px 16px;
      border-radius: 10px;
      right: 10px;
      user-select: none;
      display: none;

      li {
        list-style: none;
      }

      .dropdown-item {
        font-size: 18px;
        color: green;

        &:active {
          background-color: transparent;
          color: unset;
        }
      }
    }
  }
  .dropdown:hover .dropdown-menu {
    display: block;
  }

  .auth-username {
    font-size: 40px;
    margin-top: 20px;
    margin-right: 40px;
    cursor: pointer;
    color: white;
  }
  .auth-btn {
    margin-bottom: 10px;
    height: 70px;
    margin-right: 5px;

    transition: all 0.2s ease-in-out;

    &:hover {
      cursor: pointer;
      transform: scale(1.05);
    }
  }
}

*::-webkit-scrollbar, *::-webkit-scrollbar-button, *::-webkit-scrollbar-track, *::-webkit-scrollbar-track-piece, *::-webkit-scrollbar-thumb, *::-webkit-scrollbar-corner, *::-webkit-resizer {
  background: transparent;
}
*::-webkit-scrollbar {
  width: 7px;
  border-radius: 5px;
}
*::-webkit-scrollbar-track-piece {
  border-radius: 5px;
}
*::-webkit-scrollbar-thumb {
  background: #9e9e9e;
  border-radius: 5px;
}
*::-webkit-scrollbar-thumb:hover {
  background: #858585;
}
*::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}

.connectionErrorModal {
  z-index: 100;
}


input[type=text],
input[type=email],
input[type=password] {
  -webkit-touch-callout: text;
  -webkit-user-select: text;
  user-select: text;
  text-align: center;
  font-size: 26px;
  padding: 6px;
  border: none;
  outline: none;
  box-sizing: border-box;
  color: #4a4a4a;
  background-color: #e5e3e3;
  border-radius: 10px;
}
input[type=text][disabled],
input[type=email][disabled],
input[type=password][disabled] {
  cursor: not-allowed;
  opacity: 0.7;
}

.login-modal,
.signup-modal {
input {
  font-size: 20px;
}
}

a {
  color: #6eef95;
  text-decoration: none;
}
a:hover {
  color: #29e763;
}
a:active {
  color: #16c74c;
}

.altLink {
  color: #6eef95;
  font-size: 24px;
  text-decoration: none;
}
.altLink:hover {
  color: #40ea73;
}
.altLink:active {
  color: #19de54;
}

.inline {
  display: inline-block;
}

span {
  font-size: inherit;
}

.material-icons {
  vertical-align: sub;
}

.ui-icon {
  font-size: 30px;
  vertical-align: middle;
}



.mainMenu {
  background-color: rgb(44, 118, 22);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.mainMenu.loaded {
  background-color: rgba(0, 0, 0, 0.507);
  display: block;
}

#menuContainer {
  width: 100%;
  white-space: nowrap;
  text-align: center;
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  pointer-events: auto;

}

#contentt {
  transform: translateY(-50%);
  height: 100%;
  width: 100%;
  pointer-events: none;
}

.menuCard {
  text-align: left;
  white-space: normal;
  word-wrap: break-word;
  padding: 18px;
  overflow: hidden;
}

.menuHeader {
  font-size: 24px;
  color: #dadada;
  margin-bottom: 5px;
}

.menuSubheader {
  font-size: 20px;
  color: #dadada;
  margin-bottom: 3px;
}

.menuText {
  font-size: 18px;
  color: #a8a8a8;
  margin-bottom: 10px;
}

.menuLink {
  font-size: 20px;
}

#gameName {
  font-size: 110px;
  text-shadow: #474747 3px 5px 2px;
  color: #fff;
}

.menuButton {
  text-align: center;
  font-size: 23px;
  padding: 6px;
  box-sizing: border-box;
  color: #fff;
    background-color: rgb(22, 143, 22);
  width: 100%;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: inset 0 -8px 0 rgba(0, 0, 0, 0.2941176471);
  transition: transform cubic-bezier(0.77, 0, 0.175, 1) 0.2s;
  transform: scale(1);
}
.menuButton:hover {
  transform: scale(1.05);
}
.menuButton:active {
  transform: scale(0.95);
}

#topRight1 {
  position: absolute;
  color: white;
  top: 20px;
  right: 20px;
  font-size: 24px;
  text-decoration: none;
}

#topRight2 {
  position: absolute;
  top: 54px;
  right: 20px;
  cursor: pointer;
}

#settingsButton {
  position: absolute;
  cursor: pointer;
  font-size: 35px;
  line-height: 20px;
  bottom: 12px;
  left: 12px;
  transition: transform cubic-bezier(0.77, 0, 0.175, 1) 0.1s;
}
#settingsButton:hover {
  transform: scale(1.05);
}
#settingsButton:active {
  transform: scale(0.95);
}

.fullWidth {
  width: 100%;
}

#menuCardHolder #adBelow {
  height: auto !important;
  position: absolute;
  bottom: auto;
  // center the ad
  left: 50%;
  transform: translateX(-50%);
  box-sizing: border-box;
  display: 'inline-block' !important;
}
#menuCardHolder .menu {
  display: flex;
}
#menuCardHolder .menu * {
  box-sizing: border-box;
}
#menuCardHolder .menu a {
  cursor: pointer;
}
#menuCardHolder .menu #nameInput {
  transition: transform cubic-bezier(0.77, 0, 0.175, 1) 0.2s;
  transform: scale(1);
}
#menuCardHolder .menu #nameInput:focus {
  transform: scale(1.05);
}
#menuCardHolder .menu #serverInfo {
  margin-bottom: 8px;
  text-align: center;
}
#menuCardHolder .menu .joinCard {
  width: 375px;
}
#menuCardHolder .menu .accountCard {
  width: 310px;
}
#menuCardHolder .menu .announcementCard {
  color: white;
  text-align: center;
  font-size: 19px;
  width: 1000px;
  height: 58px;
  position: fixed;
    top: -6%;
    left: 50%;
    transform: translate(-50%, -100%);
}
#menuCardHolder .menu .announcementCard .announcedate {
  color: #999999;
  font-size: 17px;
}

#menuCardHolder .menu .announcementCard .announceimportant {
  color: yellow;
}

#menuCardHolder .menu .announcementCard .announcewarning {
  color: red;
}

#menuCardHolder .menu .forumCard {
  width: 310px;
  padding: 0;
}

#linksContainer {
  position: absolute;
  bottom: 12px;
  left: 12px;
  text-align: right;
  font-size: 20px;
}

#loadingText {
  font-size: 45px;
  color: #fff;
  text-align: center;
}
#loadingText a {
  display: block;
}

.joinCardInput {
  width: 100%;
}
.joinCardInput #nameInput,
.joinCardInput #enterGame {
  height: 44px;
  top: 50%;
  width: 100%;
  margin-bottom: 10px;
}
.joinCardInput #enterGame {
  height: 74px;
  font-size: 48px;
  line-height: 50px;
  // background-image: url("../img/ui/bg.png");
  background-position: 0px 0px;
  // background-repeat: repeat;
  // animation: animatedBackground 500s linear infinite;
}
@keyframes animatedBackground {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -10000px 20000px;
  }
}

.joinCardContent {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.account-badge {
  height: 1.5em;
  -webkit-user-drag: none;
  margin-right: 4px;
}

#serverBrowser {
  border: none;
  text-align: center;
  font-size: 16px;
  padding: 6px;
  box-sizing: border-box;
  color: rgb(70, 70, 70);
  background-color: #f7faf6;
  width: 100%;
  border-radius: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}
@media only screen and (max-width: 1040px) {
  #menuCardHolder #adBelow {
    display: none;
  }
  .forumCard {
    display: none;
  }
  #menuCardHolder .menu {
    flex-direction: column;
  }
  #menuCardHolder .menu .menuCard {
    width: 85vw !important;
  }
  #menuCardHolder .menu .joinCard {
    order: -1;
  }
}
@media only screen and (max-width: 768px) {
  #menuCardHolder #adBelow {
    display: none;
  }
  #loadingText {
    margin-top: 20px;
  }
  #mainMenu {
    overflow: scroll;
  }
  #gameName {
    font-size: 55px;
    line-height: 2;
  }
  #gameName-mobile {
    display: initial !important;
  }
  #setupCard {
    width: 260px;
    margin-top: 4px;
  }
  #topRight1 {
    top: 8px;
    left: 33%;
    right: inherit;
    transform: translateX(-50%);
  }
  #topRight2 {
    top: 8px;
    left: 66%;
    right: inherit;
    transform: translateX(-50%);
  }
  #linksContainer {
    display: none;
  }
}