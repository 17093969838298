@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");

.global-leaderboard-body {

	.back-button {
		background: #d2d2d2;
    border-color: gray;
    border-radius: 25px;
    color: #000;
    cursor: pointer;
    font-size: 20px;
    font-weight: 700;
    height: 50px;
    left: 50%;
    padding: 10px;
    position: absolute;
    top: 10px;
    transform: translate(-350%,50%);
    width: 200px;
    z-index: 100
	  }

	@import '~bootstrap/scss/bootstrap';

	--bs-table-bg: transparent;
	--bs-body-bg: transparent;

	.btn {
		--bs-btn-border-radius: 0.25rem;

		&:focus {
			box-shadow: 0 0 0 0.25rem rgba(130,138,145,0.5);
		}
	}

	.dropdown {
		margin-right: 5px;

		.dropdown-menu  {
			border-radius: 0.25rem;
			background: #ffffff;
			border: 1px solid rgba(0,0,0,0.15);

			li {
				cursor: pointer;
			}
		}
	}

	background: #f9f9f9;
	font-family: "Roboto", sans-serif;

	.main-content {
		padding-top: 100px;
		padding-bottom: 100px;

		.leaderboard-card {
			background: #fff;
			margin-bottom: 30px;
			border-radius: 5px;
			overflow: hidden;
			box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

			&__top {
				background: #f9f6ff;
				padding: 20px 0 10px 0;
			}

			&__body {
				padding: 15px;
				margin-top: -20px;
			}

			&--first {
				transform: scale(1.05);

				.leaderboard-card__top {
					background: linear-gradient(45deg, #7e57c2, #ab47bc);
					color: #fff;
				}
			}
		}
		.table {
			border-spacing: 0 15px;
			border-collapse: separate;

			thead tr th,
			thead tr td,
			tbody tr th,
			tbody tr td {
				vertical-align: middle;
				border: none;

				&:nth-last-child(1) {
					text-align: center;
				}
			}

			tbody tr {
				box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
				border-radius: 5px;

				td {
					background: #fff;

					&:nth-child(1) {
						border-radius: 5px 0 0 5px;
					}

					&:nth-last-child(1) {
						border-radius: 0 5px 5px 0;
					}
				}
			}
		}
	}
}
