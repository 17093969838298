.results {
	position: fixed;
	user-select: none;
	left: 50%;
	top: 50%;
	width: 600px;
	transform: translate(-50%, -50%);
	.results-main {
  background-color: rgb(0, 0, 0, 0.7);
	text-shadow: 1px 1px 1px rgb(0, 0, 0);
  border: 5px solid #111111;
	border-radius: 10px;
	}
	animation: fadeIn 1s ease both;

	.results-title {
		margin-top: 10px;
		margin-bottom: 5px;
		text-align: center;
		color: #ffffff;
		font-size: 45px;
		font-weight: 700;

		.name {
			margin-top: 10px;
			font-size: 30px;
			color: #eef6ff;
		}
	}

	.results-container {
		text-align: center;
		margin: 0 auto 15px;

		.info {
			margin: 15px;
			color: #cccccc;
			font-size: 13px;
			text-align: center;
			display: inline-block;

			.title {
				margin-bottom: 5px;
				font-size: 22px;
				color: #f5f5f5;
			}
		}
	}

	.results-buttons {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 15px;

		.play-again {
			img {
				width: 150px;
				height: 150px;
				transition: 0.2s;

				&:hover {
					scale: 1.1;
				}
			}
		}
		.to-home {
			margin-bottom: 5px;
			img {
				width: auto;
				height: 60px;
				transition: 0.2s;

				&:hover {
					scale: 1.1;
				}
			}
		}
	}

	@keyframes fadeIn {
    from {
      opacity: 0;
      backdrop-filter: blur(0px);
    }
    to {
      opacity: 1;
      backdrop-filter: blur(8px);
    }
  }
}
