.modal {
  position: absolute;
  left: 50%;
  top: 50%;
  min-width: 300px;
  transform: translate(-50%, -50%);
  background-color: rgb(20, 20, 20) !important;
  box-shadow:  0 0 0 5px rgba(47, 47, 47, 0.98);
  color: rgb(231, 231, 231);
  border-radius: 10px;
  padding: 15px 30px;
  z-index: 5;

  .modal-close {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0px;
    right: 0px;
    width: 30px;

    cursor: pointer;
    appearance: none;
    background-color: #000;
    border: 2px solid #fff;
    height: 30px;
    line-height: normal;
    border-radius: 30px;
    transform: rotate(0deg);
    transition: all 0.2s linear;

    &::before, &::after {
      content: ' ';
      display: block;
      width: 20px;
      height: 2px;
      background-color: #fff;
      transform: rotate(45deg);
      margin: 0 auto;
      position: absolute;
    }
    &::after {
      transform: rotate(-45deg);
    }
    &:hover {
      transform: rotate(180deg);
    }
  }
}
