.login-modal {
  display: flex;
  flex-direction: column;
  align-items: center;

  input {
    margin: 5px;
    font-size: 20px;
    font-weight: bold;
  }

  button {
    width: 120px;
    color: white;
    background-color: #4caf50;
    transition: all 0.2s ease-in;
    border: none;
    border-radius: 10px;
    font-size: 22px;
    padding: 5px;
    margin-top: 10px;

    &:hover {
      color: #4caf50;
      background-color: white;
      cursor: pointer;
    }
  }
}
