.profile-body {
  @import '~bootstrap/scss/bootstrap';

	background: darkgreen;
	font-family: "Roboto", sans-serif;;
  overflow-y: auto;

  .back-button {
    top: 10px;
    right: 10px;
    z-index: 100;
    background: rgb(1, 61, 0);
    border-radius: 25px;
    padding: 10px;
    font-size: 20px;
    font-weight: bold;
    color: white;
    cursor: pointer;
    width: 50px;
    height: 50px;
  }

  .main-content {

    h1, h2, h3, h4, h5, h6 {
      color: #fff;
    }

    .stat {
      font-size: 25px;
    }

    h1 {
      border: solid 3px #fff;
      padding: 10px;
      border-radius: 25px;
      // width fit content
      display: inline-block;
      padding-left: 10px;
      padding-right: 10px;
      background: rgb(1, 61, 1);
    }

    .equipped-skin {
      // Inlined
      display: inline-block;
      margin-right: 10px;
      // Smaller
      width: 100px;
      height: 100px;
    }

    .cluster {
      background-color: rgb(1, 61, 0);
      border: 1px solid rgb(1, 28, 1);
      border-radius: 25px;
      padding-left: 10px;
      padding-top: 10px;
    }

    .container {
      border: solid 5px #fff;
      padding: 10px;
      border-radius: 25px;
    }

    .statsContent {

    }
    .xp-graph {
      height: calc(100vh - 800px); // Adjust the value as needed
      background-color: rgb(1, 51, 1); // Change to the desired background color
      border-radius: 5px; // Optional, for rounded corners
      padding: 10px; // Optional, for some spacing around the graph
    }
    .leaderboard-card {
      background: rgb(1, 28, 1);
      margin-bottom: 30px;
      border-radius: 5px;
      overflow: hidden;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

      &__top {
        background: rgb(1, 61, 0);
        padding: 20px 0 10px 0;
      }
      &__body {
        padding: 15px;
        margin-top: -40px;
        z-index: inherit;
      }
      &__first {
        transform: scale(1.05);

        .leaderboard-card__top {
          background: linear-gradient(45deg, #7e57c2, #ab47bc);
          color: #fff;
        }
      }
    }
  }
}


